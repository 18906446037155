import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Headline, Icon } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import styles from './AppointmentChoice.module.scss';

export function AppointmentChoice() {
  const navigate = useNavigate();

  const navigateToOfflineAppointments = () => {
    navigate('/profile/offline-first-appointment');
  };

  const navigateToOnlineAppointments = () => {
    navigate('/profile/online-first-appointment');
  };

  return (
    <React.Fragment>
      <Header />
      <div className={styles.AppointmentChoice}>
        <Icon icon='calendarWithDays' size={80} color={'#3db384'} />
        <Headline level={3}>Bitte wählen Sie die Art Ihres Termins</Headline>
        <div className={styles.InfoText}>
          Ich möchte meinen <b>Termin vor Ort</b> in einem der nowomed Therapiezentren buchen und kann direkt im
          Anschluss meine Medikation persönlich abholen.
        </div>
        <div className={styles.ButtonContainer}>
          <Button onClick={navigateToOfflineAppointments}>Therapiezentrum wählen</Button>
        </div>
        <div className={styles.InfoText}>
          Ich möchte einen <b>Online-Termin</b> buchen und mein E-Rezept in einer Versandapotheke einlösen.
        </div>
        <div className={styles.ButtonContainer}>
          <Button onClick={navigateToOnlineAppointments}>Online-Termin buchen</Button>
        </div>
      </div>
    </React.Fragment>
  );
}
