import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from '../../../atoms';
import style from './StageElements.module.scss';

type PrescriptionProps = {
  prescriptions: any;
};

const cx = classNames.bind(style);

const prescriptionContainerClasses = cx({ PrescriptionContainer: true }, 'fullWidthSectionInContainer');
const prescriptionContentClasses = cx({ PrescriptionContent: true });
const prescriptionHeaderClasses = cx({ PrescriptionHeader: true });
const prescriptionIconClasses = cx({ PrescriptionIcon: true });
const prescriptionTitleClasses = cx({ PrescriptionTitle: true });
const titleClasses = cx({ DescriptionTitle: true });
const prescriptionStatusClasses = cx({ PrescriptionStatus: true });
const prescriptionNoticeClasses = cx({ PrescriptionNotice: true });
const preparationContainerClasses = cx({ PreparationContainer: true });
const preparationEntryClasses = cx({ PreparationEntry: true });

export const PrescriptionInfo = (props: PrescriptionProps) => {
  const { prescriptions } = props;

  const latestPrescription = prescriptions[0];

  let status = 'unbekannt';
  console.log(latestPrescription.status);
  switch (latestPrescription.status) {
    case 1:
      status = 'in Ausstellung';
      break;
    case 2:
      status = 'in Ausstellung'; // truth: rejected by doctor in zoho
      break;
    case 3:
      status = 'an Apotheke übermittelt';
      break;
    case 4:
      status = 'von Apotheke abgelehnt';
      break;
    case 5:
      status = 'von Apotheke akzeptiert';
      break;
    case 6:
      status = 'zurückgezogen';
      break;
    default:
      status = 'unbekannt';
  }

  /*
  Let's try to get a nice formatted preparation entry. In DB they look like
  420 EVOLUTION 25/1 CA ICC (Ice Cream Cake Kush Mints), 20g, unzerkleinert, max. TD 1g, ED 1g, verdampfen und inhalieren
  Goal is to display the part with the product and cultivar bold. The rest regular. Delimiter is ")," --- but only the first occurance
  */
  const formatPreparationEntry = (entry: string) => {
    const splitted = entry.split(/\),(.+)/);
    if (splitted.length >= 2) {
      return (
        <>
          <b>{splitted[0]})</b>, {splitted[1]}
        </>
      );
    }

    return entry;
  };

  return (
    <div className={prescriptionContainerClasses}>
      <div className={prescriptionContentClasses}>
        <div className={prescriptionHeaderClasses}>
          <div className={prescriptionIconClasses}>
            <Icon icon='document-plus' size={60} />
          </div>
          <div className={prescriptionTitleClasses}>
            <div className={titleClasses}>Mein aktuelles e-Rezept</div>
            <div className={prescriptionStatusClasses}>
              <b>Datum:</b> {latestPrescription.createdAt}
            </div>
            <div className={prescriptionStatusClasses}>
              <b>Status:</b> {status}
            </div>
          </div>
        </div>
        {latestPrescription.preparations?.length > 0 && (
          <div className={prescriptionNoticeClasses}>Auf Ihrem Rezept sind folgende Präparate:</div>
        )}
        <div className={preparationContainerClasses}>
          {latestPrescription.preparations?.length > 0 &&
            latestPrescription.preparations.map((preparation: any, index: number) => (
              <div className={preparationEntryClasses} key={index}>
                {formatPreparationEntry(preparation.name)}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
