import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DOCTOR_SLOT } from '../../../../enums/doctorslot.enum';
import UserService from '../../../../services/User.service';
import { useAppDispatch } from '../../../../store/hooks';
import { setAuth } from '../../../../store/reducers';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Headline, Icon, Loading } from '../../../atoms';
import { Calendar } from '../../../organisms/Calendar/Calendar';
import style from './StepTwoOfflineFirstAppointment.module.scss';

const cx = classNames.bind(style);
const stepsOfflineAppointmentClasses = cx({ StepTwoOfflineFirstAppointment: true, StepsContainer: true });
const calendarContainerClasses = cx({ CalendarContainer: true });
const buttonConatinerClasses = cx({ ButtonContainer: true });
const backButtonClasses = cx({ BackButton: true });

export function StepTwoOfflineFirstAppointment({ setCurrentPage, firstAppointmentInfo, setFirstAppointmentInfo }: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [doctorSlots, setDoctorSlots] = useState<DoctorSlot[]>([]);
  const [allDoctorSlots, setAllDoctorSlots] = useState<DoctorSlot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<DoctorSlot>();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  const getDoctorSlots = (doctorIds: [number]) => {
    setLoading(true);
    UserService.getMultipleDoctorSlotsBySlotType(doctorIds, DOCTOR_SLOT.FIRST_APPOINTMENT).then((res) => {
      if (res.error) {
        if (res.message.response.status === 401) {
          dispatch(setAuth({ isAuthenticated: false }));
          setLoading(false);
          navigate('/login');
        }
        console.log(res.message);
      }
      if (!res.data.length) {
        setLoading(false);

        return;
      }
      console.log('SLOTS', res.data);
      setAllDoctorSlots(res.data);
      setLoading(false);
    });
  };

  const doctorSlotsFilter = () => {
    const slotsForOfflineFirstAppointment = allDoctorSlots.filter(
      (slot: any) => slot.type === 0 && slot.subtype === 0 && slot.practiceId === firstAppointmentInfo.location.id
    );
    setDoctorSlots(slotsForOfflineFirstAppointment);
  };

  useEffect(() => {
    if (firstAppointmentInfo.selectedDate !== undefined) {
      setSelectedDate(firstAppointmentInfo.selectedDate);
    }
    getDoctorSlots(firstAppointmentInfo.location.doctorsIds);
  }, []);

  useEffect(() => {
    const slots = doctorSlots.filter(
      (slot: DoctorSlot) =>
        new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() === new Date(selectedDate).getTime()
    );
    setSelectedSlot(slots[0]);
  }, [selectedDate, doctorSlots]);

  const next = () => {
    if (selectedSlot) {
      setFirstAppointmentInfo({
        ...firstAppointmentInfo,
        selectedDate,
        doctorSlots: doctorSlots.filter(
          (slot: DoctorSlot) =>
            new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() - selectedDate.getTime() === 0
        ),
      });
    }
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        offlineFirstAppointmentIndex: 3,
      },
    };
    setCurrentPage(reducerType);
  };

  useEffect(() => {
    doctorSlotsFilter();
  }, [allDoctorSlots]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={stepsOfflineAppointmentClasses}>
      <Icon icon='calendarWithDays' size={80} color={'#3db384'} />
      <Headline
        level={3}
      >{`Wann soll Ihr Erstgespräch im Therapiezentrum ${firstAppointmentInfo.location.name} stattfinden?`}</Headline>
      {doctorSlots?.length !== 0 ? (
        <div className={calendarContainerClasses}>
          <Calendar slots={doctorSlots} onChange={setSelectedDate} selectedDate={selectedDate} />
        </div>
      ) : (
        <div>
          <div>
            <div>Leider sind derzeit keine Termine verfügbar</div>
            <div>Bitte wählen Sie einen anderen Standort oder setzen Sie sich mit unserem Support in Verbindung.</div>
            <div>
              <div
                onClick={() => {
                  const reducerType = {
                    type: '[UPDATE]',
                    payload: { offlineFirstAppointmentIndex: 1 },
                  };
                  setCurrentPage(reducerType);
                }}
              >
                Anderen Standort wählen
              </div>
            </div>
          </div>
        </div>
      )}

      {doctorSlots?.length !== 0 && (
        <div>
          <div className={buttonConatinerClasses}>
            <Button disabled={!selectedDate || doctorSlots.length === 0} onClick={next}>
              Weiter
            </Button>
            <a
              className={backButtonClasses}
              onClick={() => {
                const reducerType = {
                  type: '[UPDATE]',
                  payload: {
                    offlineFirstAppointmentIndex: 1,
                  },
                };
                setCurrentPage(reducerType);
              }}
            >
              Zurück
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
