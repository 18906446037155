import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

export const BookFollowUpAppointment = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <Icon icon='calendarWithDays' size={70} color={'white'} />
        <div className={classes.CircleText}>
          Videosprechstunde
          <br />
          buchen
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>Buchen Sie jetzt Ihre Videosprechstunde</div>
        <div className={classes.DescriptionText}>
          Planen Sie ausreichend Zeit für Bearbeitung und Rezeptversand ein.
        </div>
        <div className={classes.ActionButtons}>
          <Button onClick={() => navigate('/profile/online-appointment')}>Jetzt Termin buchen</Button>
        </div>
      </div>
    </>
  );
};
